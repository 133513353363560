<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <div class="text-h5">
          About
        </div>

        <div
          class="text-body-1" 
          :style="`
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            max-height: ${showMore ? '15em' : '4.5em'};
            transition: max-height .5s linear;
          `"
        >
          {{ persona.about }}
        </div>

        <span
          v-if="persona.about && persona.about.length > 230"
          :class="{ 'clickable font-weight-bold grey--text': true, 'text--lighten-4': $vuetify.theme.dark, 'text--darken-4': !$vuetify.theme.dark }"
          @click="showMore = !showMore"
        >
          {{ showMore ? 'Show less' : 'Show more' }}
        </span>
      </v-col>

      <v-col cols="6">
        <!-- This helps us apply the right theme color -->
        <v-container class="pl-0">
          <v-list-item three-line class="pl-0">
            <v-list-item-content>
              <v-list-item-title class="text-h6 mb-1">
                Ambers x10
              </v-list-item-title>
              <v-list-item-subtitle>
                This is a top-tier persona, 180+ students enaged here!!
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              rounded="lg"
              size="60"
              color="grey"
            >
              <v-img :src="ambersSymbol"></v-img>  
            </v-list-item-avatar>
          </v-list-item>
        </v-container>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="6">
        <div class="text-h5">
          Your stats
        </div>
        <v-divider inset class="my-2" />

        <!-- This helps us apply the right theme color -->
        <div class="chart-canvas">
          <canvas id="myChart" width="400" height="400"></canvas>
        </div>
      </v-col>

      <v-col cols="6">
        <div class="text-h5">
          {{ profiling.name }}
        </div>
        <div class="text-body-1">
          {{ profiling.description }}
        </div>

        <br/>

        <div class="text-h5">
          Recent History
        </div>
        <div class="text-body-1">
          {{ profiling.history }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@api'

export default {
  props: {
    persona: {
      type: Object,
      default: () => ({})
    }
  },

  mounted() {
    this.updateChart()
  },

  watch: {
    profiling: {
      deep: true,
      handler: function() {
        this.updateChart()
      }
    }
  },

  data: () => ({
    ambersSymbol: 'https://hisolver-files.s3.amazonaws.com/images/ambers-v1.png',
    showMore: false,
    myRadarChart: null
  }),

  computed: {
    ...mapGetters({
      profiling: 'persona/profiling',
      personaConnector: 'persona/connector'
    })
  },

  methods: {
    async updateChart() {
      const ctx = document.getElementById('myChart').getContext('2d');

      await API().put('connector', {
        profiling: this.profiling
      }, {
        params: {
          id: this.personaConnector.id
        }
      })
    
      this.myRadarChart = new Chart(ctx, {
        type: 'radar',
        data: {
          labels: ['Exploratory', 'Practical', 'Interactive', 'Structured', 'Conceptual', 'Observational'],
          datasets: [{
            label: 'Your learning profile',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            pointBackgroundColor: 'rgba(255, 99, 132, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
            data: this.profiling.data
          }]
        },
        options: {
          elements: {
            line: {
              tension: 0 // disables bezier curves
            }
          },
          scales: {
            r: {
              angleLines: {
                display: true
              },
              suggestedMin: 0,
              suggestedMax: 1,
              ticks: {
                stepSize: 0.2 // Adjust step size as needed
              }
            }
          }
        }
      });
    }
  }
}
</script>

<style>
.theme--dark .chart-canvas canvas {
  filter: invert(1) hue-rotate(180deg);
}
</style>
import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    createOrUpdate (profileId, personaId, clicked = false) {
      return apiRequest.post(
        `persona-engagement/${userId}`,
        {
          profile: profileId,
          persona: personaId,
          clicked
        }
      )
    }
  }
}

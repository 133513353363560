<template>
  <div>
    <v-row
      justify="center"
      align="start"
      class="px-3"
    >
      <v-col
        xl="9"
        lg="8"
        class="py-0"
      >
        <v-list-item
          three-line
          class="px-0"
        >
          <v-list-item-avatar
            rounded="xl"
            size="100"
            :class="{
              'grey lighten-2': !$vuetify.theme.dark,
              'grey darken-4': $vuetify.theme.dark
            }"
          >
            <v-img :src="getResourceUrl(persona.avatar)"></v-img>
          </v-list-item-avatar>
          
          <v-list-item-content>
            <v-container class="py-0 pr-0">
              <v-badge
                inline
                avatar
                color="transparent"
              >
                <template v-slot:badge>
                  <v-avatar v-if="persona.verified">
                    <v-img
                      contain
                      height="24"
                      width="24"
                      src="@assets/svgs/checkmark.svg"
                    />
                  </v-avatar>
                </template>

                <v-list-item-title :class="`text-h4`">
                  {{ persona.name }}
                </v-list-item-title>
              </v-badge>

              <v-list-item-subtitle>
                {{ persona.caption }}
              </v-list-item-subtitle>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col
        xl="3"
        lg="4"
        class="py-0"
      >
        <!-- The CreatorCard default sender is "anonymous" -->
        <CreatorCard
          v-if="persona.isAnonymous"
          :showArrow="true"
        />
        <CreatorCard
          v-else
          :showArrow="true"
          :creator="persona.sender"
        />
      </v-col>
    </v-row>
    
    <v-row class="px-6">
      <v-btn
        large
        elevation="0"
        class="primary"
        style="border-radius: 12px;"
        @click="openChat"
      >
        {{ $t('openChat') }}
        <v-icon right>
          {{ mdiArrowRight }}
        </v-icon>
      </v-btn>

      <v-tooltip
        v-if="persona.sender &&
          persona.sender.profile === myProfileId"
        bottom
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              large
              rounded
              icon
              depressed
              class="ml-2"
              :style="`background-color: ${
                $vuetify.theme.dark ? '#424242' : '#E0E0E0'
              }`"
              @click="edit"
            >
              <v-icon>{{ mdiPencil }}</v-icon>
            </v-btn>
          </div>
        </template>
        <span>
          {{ $t('edit') }}
        </span>
      </v-tooltip>

      <div class="mx-1"></div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              large
              rounded
              icon
              depressed
              :color="favorite ? 'tertiary' : ''"
              :style="`background-color: ${
                $vuetify.theme.dark ? '#424242' : '#E0E0E0'
              }`"
              @click="addFavorites"
            >
              <v-icon>{{ favorite ? mdiStar : mdiStarOutline }}</v-icon>
            </v-btn>
          </div>
        </template>
        <span>
          {{ $t('addFavorites') }}
        </span>
      </v-tooltip>

      <div class="mx-1"></div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <!-- TODO: abstract the rounded style -->
            <!-- See the Hall/Control -->
            <v-btn
              large
              rounded
              icon
              depressed
              :style="`background-color: ${ $vuetify.theme.dark ? '#424242' : '#E0E0E0' }`"
              @click="copyUrl"
            >
              <v-icon>{{ mdiShareOutline }}</v-icon>
            </v-btn>
          </div>
        </template>
        <span>
          {{ $t('share') }}
        </span>
      </v-tooltip>
    </v-row>

    <div class="my-4"></div>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      class="py-0 px-0"
    >
      <v-tab :ripple="false" class="font-weight-bold text-caption">About</v-tab>
      <v-tab :ripple="false" class="font-weight-bold text-caption">Content</v-tab>
      <!-- <v-tab :ripple="false" class="font-weight-bold text-caption">Stats</v-tab> -->
    </v-tabs>

    <PersonaChart
      v-if="tab === 0 && !annaChamber"
      :persona="persona"
    ></PersonaChart>

    <v-container v-if="tab === 1">
      <v-row class="px-3">
        <v-col cols="8">
          <div class="text-h5">
            Content
          </div>
          <div class="text-body-2">
            AI Content: chat YouTube videos, create assignments and study sessions
          </div>          
        </v-col>

        <v-col cols="4">
          <v-btn
            large
            block
            elevation="0"
            color="primary"
            class="hs-rounded-12"
            @click="createContent = !createContent"
          >
            new content
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="!createContent" class="px-3">
        <v-col cols="9" class="px-2">
          <v-card outlined class="hs-rounded-12 mb-4" v-for="i in 10" :key="i">
            <v-container>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    Coming soon
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    A new way of learning and sharing knowledge
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  rounded="lg"
                  size="60"
                  color="grey lighten-2"
                >
                  <v-img>
                    <template v-slot:placeholder>
                      <v-skeleton-loader
                        class="mx-auto"
                        height="60"
                        width="60"
                        type="image"
                      />
                    </template>
                  </v-img>
                </v-list-item-avatar>
              </v-list-item>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="createContent">
        <v-row class="px-3">
          <v-col col="12">
            <div class="text-h6">Notebooks</div>
            <div class="text-body-2">The notebook templates can be used to create general purpose notes, guided study sessions, or problem sets</div>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col cols="4">
            <v-card class="hs-rounded-12">
              <v-img
                src="https://image.lexica.art/full_webp/b52ca079-cc49-4146-9862-d4b93e07aad0"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                aspect-ratio="1.2"
              >
                <v-card-title>
                  Notes
                </v-card-title>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="hs-rounded-12">
              <v-img
                src="https://image.lexica.art/full_webp/8f2210d7-c9a4-4303-ae3d-5fa8587fb1bb"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                aspect-ratio="1.2"
              >
                <v-card-title>
                  Study
                </v-card-title>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="hs-rounded-12">
              <v-img
                src="https://image.lexica.art/full_webp/0e126861-99c1-423c-b8b5-5d473bef7d20"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                aspect-ratio="1.2"
              >
                <v-card-title>
                  Problems
                </v-card-title>
              </v-img>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="px-3">
          <v-col col="12" class="py-0">
            <div class="text-h6">Media (Images)</div>
            <div class="text-body-2">Upload images here</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" clas="px-0">
            <FileInput />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import {
  mdiArrowRight,
  mdiPencil,
  mdiStar,
  mdiStarOutline,
  mdiShareOutline,
  mdiCheck
} from '@mdi/js'
import { getResourceUrl } from '@utils'
import { CreatorCard } from '@components'
import { FileInput } from '@components/File'
import PersonaChart from './PersonaChart'
import API from '@api'
import ObjectID from 'bson-objectid'
import PersonaFunctions from './PersonaFunctions.vue'
import CreateContent from './CreateContent.vue'
import PersonaEngagementService from '@api/personaEngagement'

export default {
  components: {
    FileInput,
    CreateContent,
    CreatorCard,
    PersonaChart
  },

  props: {
    persona: {
      type: Object,
      default: () => ({})
    }
  },

  async created() {
    this.personaEngagementService = PersonaEngagementService(this.user.id)
    this.resetPersona = PersonaFunctions.resetPersona.bind(this);
    this.setPersonaFolder = PersonaFunctions.setPersonaFolder.bind(this);
    
    this.loadConnector()
    
    setTimeout(() => {
      this.activated = this.currentPersona.id === this.persona.id
    }, 100)
  },

  beforeDestroy() {
    this.RESET_PROFILING()
    this.SET_CONNECTOR({})  
  },

  data: () => ({
    mdiArrowRight,
    mdiPencil,
    mdiCheck,
    mdiStar,
    mdiStarOutline,
    mdiShareOutline,
    activated: false,
    favorite: false,
    tab: 0,
    createContent: false,
    personaEngagementService: null
  }),

  watch: {
    persona: {
      deep: true,
      handler() {
        // important to set that here too because on the created hook
        // it might spill the old persona value
        // e.g.: user access another persona before this one
        //this.loadConnector()
      }
    },
    currentPersona: {
      deep: true,
      handler() {
        this.activated = this.currentPersona.id === this.persona.id
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfileId: 'profile/id',
      currentPersona: 'currentPersona',
      personaFavorites: 'persona/favorites',
      myFolders: 'folders/myFolders',
      tools: 'persona/tools',
      personaConnector: 'persona/connector',
      annaChamber: 'annaChamber'
    }),

    backgroundColor() {
      return this.$vuetify.theme.dark ? '#2a2a2a' : '#f2f2f2'
    }
  },

  methods: {
    ...mapActions({
      SET_PERSONA: 'SET_PERSONA',
    }),

    ...mapMutations({
      SET_CONNECTOR: 'persona/SET_CONNECTOR',
      SET_TOOLS: 'persona/SET_TOOLS',
      SET_ROLES: 'persona/SET_ROLES',
      SET_RAG: 'folders/setRag',
      SET_CONTENTS: 'folders/setContents',
      SET_CURRENT_FOLDER: 'folders/setCurrentFolder',
      SET_ANNA_CHAMBER: 'setAnnaChamber',
      RESET_PROFILING: 'persona/RESET_PROFILING',
      SET_PROFILING: 'persona/SET_PROFILING',
      ADD_FAVORITE: 'persona/ADD_FAVORITE',
      REMOVE_FAVORITE: 'persona/REMOVE_FAVORITE',
      RESET_PERSONA: 'RESET_PERSONA'
    }),

    getResourceUrl,

    async loadConnector() {
      /*
        Resets the connector in casethere's another persona acticated
      */
      this.SET_CONNECTOR({})

      const [connector] = await API().get('connectors', {
        params: {
          query: {
            $and: [
              // NEED TO IMPORT ObjectID from 'bson-objectid'
              { "entities": { $elemMatch: { "ref": "Persona", "_id": ObjectID(this.persona.id) } } },
              { "entities": { $elemMatch: { "ref": "Profile", "_id": ObjectID(this.myProfileId) } } }
            ]
          }
        }
      });

      if (connector) {
        this.SET_CONNECTOR(connector)
        if (connector.profiling) {
          this.SET_PROFILING(connector.profiling)
        }
        this.favorite = connector && connector.favorite && connector.state === 'ACTIVE'
      }
    },

    async addFavorites() {
      if (this.personaConnector && this.personaConnector.id) {
        await API().put('connector', {
          favorite: !this.personaConnector.favorite
        }, {
          params: {
            id: this.personaConnector.id
          }
        })

        this.favorite = !this.favorite
      } else {
        await this.createConnector(false, true)
        this.favorite = true
      }

      if (this.favorite)
        this.ADD_FAVORITE(this.persona)
      else {
        let idx = this.personaFavorites.map(({ id }) => id).indexOf(this.persona.id)
        this.REMOVE_FAVORITE(idx)
      }
    },

    async loadPersonaFolder(reset = false) {
      if (reset) {
        this.resetPersona()
        return;
      }
      
      // Loads the default persona folder
      const [personaFolder] = await API().get(`folders/${this.user.id}`, {
        params: {
          query: {
            persona: {
              $eq: this.persona._id
            }
          }
        }
      })

      // Sets the persona folder and its contents
      if (personaFolder) {
        this.setPersonaFolder(personaFolder)
      }
    },

    async createConnector(update = false, favorite) {
      if (update) {
        const newConnector = {
          ...this.personaConnector
        }
        newConnector.tools = this.tools
        this.SET_CONNECTOR(newConnector)

      } else {
        this.SET_CONNECTOR(
          await API().post('connector', {
            entities: [{
              _id: this.myProfileId,
              ref: 'Profile'
            }, {
              _id: this.persona.id,
              ref: 'Persona'
            }],
            type: 'persona_profile',
            tools: this.tools,
            ...(typeof favorite != "undefined" ? { favorite } : {})
          })
        )
      }
    },

    async togglePersona() {
      this.loadPersonaFolder(this.activated)
      this.activated = !this.activated

      const persona = this.currentPersona.id === this.persona.id ? {} : this.persona

      if (this.personaConnector.tools && this.personaConnector.tools.length > 0) {
        persona.tools = this.personaConnector.tools        
        if (!this.activated) persona.roles = []
      }

      this.SET_PERSONA(persona)      

      if (this.activated && (!this.personaConnector || !this.personaConnector.id)) {
        // create a connector
        await this.createConnector(false)
      } else {
        // update the connector tools
        await this.createConnector(true)
      }
    },

    async openChat() {
      // activates the persona and opens the chat with it
      this.loadPersonaFolder()
      this.activated = true

      let persona = { ...this.persona }
      if (this.personaConnector.tools && this.personaConnector.tools.length > 0)
        persona.tools = this.personaConnector.tools

      this.SET_PERSONA(persona)
      this.SET_ANNA_CHAMBER()

      if (!this.personaConnector || !this.personaConnector.id)
        await this.createConnector(false)
      else
        await this.createConnector(true)

      this.personaEngagementService.createOrUpdate(this.myProfileId, this.persona.id)
    },

    copyUrl() {
      this.$copyText(`${window.location.origin}${this.$route.fullPath}&activate=true`)
      this.$emit('snackMsg', 'shareLinkCopied')
    },

    edit() {
      /*
       * Note that setting the persona here
       * will allows us to always have the role in the editing phase
       * and we know that the persona will be full in this component
       * because it is fully loaded in the persona page
       * (we retrieve it from the db with its query id).
       * This is important because to keep persona persisted with cookies
       * we need to split the persona roles so that the cookie won't blow > 3Kb
       * (roles is an array that can be larger)
      */
      this.$router.push(`/personas/edit?id=${this.persona.id}`)
    }
  }
}
</script>

<style scoped>
/* Round buttons */
.v-btn--rounded {
  border-radius: 15px; 
}
</style>
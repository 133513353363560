<template>
  <v-container style="position: relative;">
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="mx-auto"
        max-width="344"
        hover
        :elevation="hover ? 8 : 0"
        style="border-radius: 12px; transition: all 0.2s ease;"
        :ripple="false"
        @click="onClick"
      >
        <v-list-item three-line>
          <v-list-item-avatar
            rounded
            size="80"
            color="grey"
          >
            <v-img :src="getResourceUrl(persona.avatar)" aspect-ratio="1.7"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ persona.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ persona.about }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-list-item class="px-2">
            <v-list-item-avatar size="30">
              <v-img :src="getResourceUrl(sender.avatar)" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle class="list-item-content">
                {{ sender.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-spacer></v-spacer>

          <v-btn
            v-if="buzz"
            icon
            small
            :class="iconClass"
          >
            <v-icon :color="iconColor" small>{{ mdiFire }}</v-icon>
          </v-btn>

          <v-avatar size="30">
            <v-img
              contain
              height="15"
              width="15"
              src="@assets/svgs/checkmark.svg"
            >
            </v-img>
          </v-avatar>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { getResourceUrl } from '@utils'
import { mdiFire, mdiStarFourPointsOutline } from '@mdi/js'
import PersonaEngagementService from '@api/personaEngagement'

export default {
  props: {
    persona: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profileId: 'profile/id',
    }),

    sender() {
      return this.persona.isAnonymous
        ? this.anonymous : this.persona.sender
    },

    iconColor() {
      return this.buzz ? 'orange darken-3' : ''
    },

    iconClass() {
      return this.buzz ? 'buzzing' : ''
    },
  },

  created() {
    this.personaEngagementService = PersonaEngagementService(this.user.id)
  },

  data: () => ({
    mdiFire,
    mdiStarFourPointsOutline,
    
    anonymous: {
      avatar: 'https://image.lexica.art/full_webp/671760b0-293a-4139-bdb6-dfbefe4a077a',
      name: 'Anonymous'
    },
    buzz: Math.random() > 0.5 ? true : false, // TODO: actually compute buzz
    personaEngagementService: null
  }),

  methods: {
    getResourceUrl,
    onClick() {
      this.personaEngagementService.createOrUpdate(this.profileId, this.persona.id, true)
      this.$router.push(`/personas?id=${this.persona.id}`)
    }
  }
}
</script>

<style scoped>
.v-list--three-line .v-list-item .v-list-item__subtitle, .v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 3;
}
</style>